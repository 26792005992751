#Task-Section.Task-Section{
    border-left-width:3rem;
    border-left-style:solid;
    padding-left: 1rem;
}
#Task-Section.Task-Section.yellow{
    border-left-color:#fbbd08;
}
#Task-Section.Task-Section.green{
    border-left-color:#21ba45;
}
#Task-Section.Task-Section.red{
    border-left-color:#db2828;
}

#Task-Section.Task-Section .section-header{
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 70%;
}