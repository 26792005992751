.page-wrapper{
    margin-top: 65px;
	min-width: 40px;
	height: 85vh;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
}

.message-wrapper{
    position: sticky; 
    top: 65px; 
    left: 0px; 
    z-index: 10;
}

.form-wrapper{
    width: 25%;
    min-width: 400px;
    margin: 10px;
    margin-left: 0px;
}
.ui.form.form-wrapper >.field >label{
    padding-top:1em;
}

button.ui.icon.button.grid-button{
    background-color: #e8e8e8;
	padding: .5em .5em .5em;
	margin-left: .5em;
}