.analytics-pipeline-overview{
    display:flex; 
    align-items:center;
    flex-wrap:wrap;
    flex-direction:space-between;
}

.analytics-pipeline-overview .ui.statistic.pipeline-statistic{
    margin:10px;
}
.analytics-pipeline-overview .ui.statistic.pipeline-statistic .pipeline-statistic-icon{
    padding-right: .5rem;
}


.analytics-pipeline-overview .ui.statistic.pipeline-statistic:first-child{
    /* semantic ui makes the darndest design decisions; the first child of a statistic has its margin-top set to 0 for some reason.
    this code changes that. */
    margin-top:auto;
}

.analytics-pipeline-flex-menu{
    display:flex;
    flex-wrap: wrap;
}
.analytics-pipeline-flex-menu .flexible-menu-item{
    border-bottom:1px solid rgba(34,36,38,.15);
    border-top:1px solid rgba(34,36,38,.15);
}

.ui.basic.segment.error-segment{
    padding-bottom:0px;
}
.ui.basic.segment.loading-segment{
    height: 7rem;
}