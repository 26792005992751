
// Need to add .ui.menu for css specificity to be more than semantic-ui

.omni-navbar.ui.menu {
    background-color: #0f4b90;
}

.omni-navbar.ui.menu .item,.menu {
    color: #FFFFFF;

	:hover > & {
		color: #FFFFFF;
	}
}

.omni-navbar.ui.menu i.icon {
	margin: 0;
}


.no-resize{
	/* the !important is gross, but the class keeps getting overwritten by existing semantic-UI styles */
	resize:none !important;
}

.ui > i.navbar.icon-button.icon {
	margin: 0px;
	color: white;
}

.ui > i > i.navbar.icon-button.icon.bell {
	color:white;
}


/*************Attachment related styling****************/
.navbar.bug-modal.attachment{
	display:flex;
	justify-content:space-between;
	align-items:center;
}

/* if ::after content isn't removed then the remove button ends up in the middle of the list item instead of the end */
.navbar.bug-modal.attachment::after{
	content:none;
}

.navbar.bug-modal.attachment .list-content{
	display:flex;
	align-items:center;
}

.navbar.bug-modal.attachment .list-content img.thumbnail{
	/* semantic ui adds padding to the bottom of Image components, in this case it throws off
		list item alignments so it gets overridden here */
	padding-bottom:0px !important;
	
	margin:10px !important;
}
