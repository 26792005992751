.step.cardView,
.step.opportunity {
	border-right: 1px solid rgba(252, 252, 252, 0.5) !important;
}

.step.cardView::after,
.step.opportunity::after {
	background-color: #2285d1 !important;
	border-color: rgba(251, 251, 251, 0.5) !important;
}

.link.step.cardView:hover,
.link.step:active {
	color: rgba(255, 255, 255, 1) !important;
}

.link.step.opportunity:hover,
.link.step:active {
	color: rgba(255, 255, 255, 0.8) !important;
}

.link.step.opportunity.active {
	background-color: #1e91e9 !important;
}

.link.step.opportunity.toBeCompleted {
	background-color: #a5a5a5 !important;
}

.link.step.opportunity.toBeCompleted::after {
	background-color: #a5a5a5 !important;
}

.link.step.opportunity.active::after {
	background-color: #1e91e9 !important;
}

.step.opportunity.active .title, .step.opportunity.active .currentIcon {
	color: #ffffff !important;
}

.link.step.cardView {
	cursor: default !important;
}

.gridColumn {
	height: 84vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.cardCount {
	padding-left: 1rem;
}

.gridColumn::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 0;
	height: 0;
}

.borderBottom > .segment {
	border-bottom: #c9c9c9 1px solid !important;
}
