html {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	min-height: 100%;
}

datalist {
	height: 1em;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Imported from OMNI_overwrite CSS file */
.fixFloat {
	float: none !important;
}

.info {
	background: white !important;
	float: none !important;
}

.one,
.two,
.three {
	width: 100% !important;
}

/* ASD BLUE CSS */
.ui.primary.button,
.ui.primary.buttons .button {
	background-color: #0f4b90;
	color: #fff;
	text-shadow: none;
	background-image: none;
}

.ui.blue.label,
.ui.blue.labels .label {
	background-color: #0f4b90 !important;
	border-color: #0f4b90 !important;
	color: #fff !important;
}

.ui.lightBlue.label,
.ui.lightBlue.labels .label {
	background-color: #1E91E9;
	border-color: #157fd1;
	color: #fff;
}

.ui.blue.menu .active.item,
.ui.menu .blue.active.item {
	border-color: #0f4b90 !important;
	color: #0f4b90 !important;
}

.ui.blue.button,
.ui.blue.buttons .button {
	background-color: #0f4b90;
	color: #fff;
	text-shadow: none;
	background-image: none;
}

i.blue.icon {
	color: #0f4b90 !important;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
	background-color: #0f4b90 !important;
}

.ui.basic.blue.button,
.ui.basic.blue.buttons .button {
	-webkit-box-shadow: 0 0 0 1px #0f4b90 inset !important;
	box-shadow: 0 0 0 1px #0f4b90 inset !important;
	color: #0f4b90 !important;
}

.ui.grid > .blue.column,
.ui.grid > .blue.row,
.ui.grid > .row > .blue.column {
	background-color: #0f4b90 !important;
	color: #fff;
}

.step.workView.completed::after,
.step.workView.completed,
.step.cardView::after,
.step.opportunity::after {
	background-color: #0f4b90 !important;
	border-color: rgba(251, 251, 251, 0.5) !important;
	color: #fff;
}

.step.workView.completed::after,
.step.workView.completed {
	background-color: #a5a5a5 !important;
	border-color: rgba(251, 251, 251, 0.5) !important;
	color: #fff !important;
}

.link.step.workView.active::after,
.link.step.opportunity.active::after {
	background-color: #1e91e9 !important;
}

.link.step.workView.active,
.link.step.opportunity.active {
	background-color: #1e91e9 !important;
}

.link.step.workView.toBeCompleted,
.link.step.opportunity.toBeCompleted {
	background-color: #a5a5a5 !important;
}

.ui.input.hiddenInput:before,
.ui.dropdown.hiddenInput:before {
	background-color: #0f4b90 !important;
}

.ui.input.hiddenInput.disabled:before,
.ui.dropdown.hiddenInput.disabled:before {
	background-color: #9da0a3 !important;
}

.navSubDropdown .icon {
	color: #4183C4 !important;
}

.docsLink .text {
	color: #4183C4 !important;
}

/* END FIX BLUE */

/*
Fix modal alignment sticking to left
this is fixed in the next version.
See: https://github.com/Semantic-Org/Semantic-UI/issues/6646
*/
.ui.fullscreen.modal {
	left: auto !important;
}
.ui.fullscreen.scrolling.modal {
	left: auto !important;
}

/*
Fix react-datepicker to behave more like semantic-ui-css
*/
.react-datepicker__input-container,
.react-datepicker-wrapper,
.react-datepicker__input-container > input {
	width: 100%;
}

/* Pagination Grid */
.gridPagniationFix.ag-theme-balham .ag-paging-panel {
	display: block;
	margin: 1vh;
	border-top: none;
}

/* Remove arrows from input */
.agGridNumberInput::-webkit-inner-spin-button,
.quantity::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.ui.basic.label.fixBoldness {
	color: rgba(0, 0, 0, 0.5);
}

.ui.labeled.input.fixBorderFocus:not([class*='corner labeled']) .label:first-child + input:focus {
	border-left-color: transparent;
}

.ui.input.fixBorderFocus.focus > input,
.ui.input.fixBorderFocus > input:focus {
	border-color: rgba(34, 36, 38, 0.15);
}

/* WORK FIX MARGIN ON GRID */
.ui.top.attached.label + [class*='right floated'] + *,
.ui.top.attached.label:first-child + :not(.attached).fixGridMargin {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

/* Create Work Order */
.templateDraftSection {
	display: inline-block;
}

.templateDraftSection .ui.search {
	font-size: 0.8em;
}

/* Hide WYSIWYG TinyMCE notifications */
.mce-notification-inner,
.mce-notification {
	display: none !important;
}
.ui.disabled.input.fixInputOpacity,
.ui.input:not(.disabled) input[disabled] {
	opacity: 0.8 !important;
	color: rgba(0, 0, 0, 0.8);
}

.ui.dropdown.navBarDropdown > .text {
	padding: 0.7em 0;
}

/* fixes spacing of sub dropdown pop out menu in links on nav bar */
.subDropdownPopOutSpacing {
	left: 167px !important;
}

.sendToModalDatePicker {
	font-size: 100%;
	border-radius: 4px;
	box-shadow: inset 0 2px 2px #e9e9e9;
	border: 1px solid #aeaeae;
	line-height: 1.21428571em;
	padding: 0.67857143em 2.1em 0.67857143em 1em;
}

.sendToModalDatePickerError {
	background: #fff6f6;
	border-color: #e0b4b4 !important;
}

.datePickerFluid {
	width: 100%;
}

.workOrderDatePicker {
	font-size: 100%;
	border-radius: 3px;
	border: 1px solid #dedede;
	line-height: 1.5em;
	padding: 0.67857143em 2.1em 0.67857143em 1em;
	margin-bottom: 2rem;
}

.workOrderDatePicker.react-datepicker-ignore-onclickoutside:focus {
	border-radius: 3px;
	border: 1px solid #7aaed4;
	outline: none !important;
}

.createAasdiDatePicker {
	font-size: 100%;
	border-radius: 3px;
	border: 1px solid #dedede;
	line-height: 1.5em;
	padding: 0.5em 2.1em 0.5em 1em;
	width: 23rem;
	margin-bottom: 1.75rem;
}

.createAasdiDatePicker.react-datepicker-ignore-onclickoutside:focus {
	border-radius: 3px;
	border: 1px solid #7aaed4;
	outline: none !important;
}

.workOrderDatePicker2 {
	font-size: 100%;
	border-radius: 3px;
	border: 1px solid #dedede;
	line-height: 1.5em;
	padding: 0.67857143em 2.1em 0.67857143em 1em;
	margin-bottom: 2rem;
}

.workOrderDatePicker2.react-datepicker-ignore-onclickoutside:focus {
	border-radius: 3px;
	border: 1px solid #7aaed4;
	outline: none !important;
}

.scrollingTextArea,
.createRatingTextArea {
	width: 100%;
	border: 1px solid rgba(34, 36, 38, 0.15);
	color: rgba(0, 0, 0, 0.87);
	border-radius: 0.28571429rem;
	padding: 1rem;
}

.scrollingTextArea:focus {
	border-radius: 3px;
	border: 1px solid #7aaed4;
	outline: none !important;
}

.createRatingTextArea:focus {
	border-radius: 3px;
	border: 1px solid #7aaed4;
	outline: none !important;
}

.react-datepicker__time-list {
	padding: 5%;
}

.ui.modal .createModalContent.scrolling.content {
	max-height: calc(66vh) !important;
	overflow: auto !important;
}

.visible.transition.centerDimmer {
	display: flex !important;
}

.ui.input.hiddenInput input,
.ui.dropdown.hiddenInput input {
	border: none !important;
	padding: 0;
	outline: none;
	color: rgba(0, 0, 0, 0.6);
}

.ui.input.hiddenInput input:hover,
.ui.input.hiddenInput input:focus-within,
.ui.dropdown.hiddenInput input:hover,
.ui.dropdown.hiddenInput input:focus-within {
	color: rgba(0, 0, 0, 0.87) !important;
}

.ui.input.hiddenInput,
.ui.dropdown.hiddenInput {
	position: relative;
	color: rgba(0, 0, 0, 0.6);
	text-decoration: none;
	padding-bottom: 0.3rem;
}

.ui.dropdown.hiddenInput:hover {
	color: rgba(0, 0, 0, 0.87) !important;
}

.ui.input.hiddenInput:before,
.ui.dropdown.hiddenInput:before {
	border-radius: 0 !important;
	content: '';
	position: absolute;
	width: 100%;
	height: 2px;
	bottom: 0;
	left: 0;
	background-color: rgba(34, 133, 209, 1);
	visibility: hidden;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	transform-origin: 0% 50%;
}

.ui.input.hiddenInput:focus-within:before,
.ui.input.hiddenInput:hover:before,
.ui.dropdown.hiddenInput:focus-within:before,
.ui.dropdown.hiddenInput:hover:before {
	visibility: visible;
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
}

.react-datepicker-popper {
	z-index: 200;
}

.ui.basic.red.label.crm {
	margin-top: 0.7rem !important;
}

.ui.steps.registrationStepsFix .step > .icon {
	margin: 0.25em 0;
}

.registrationExpirationDate .react-datepicker-wrapper,
.registrationExpirationDate .react-datepicker-wrapper .react-datepicker__input-container {
	width: 100%;
}

.registrationExpirationDate .react-datepicker-wrapper .react-datepicker__input-container .datePickerFluid {
	border: 1px solid rgba(34, 36, 38, 0.15);
	box-shadow: none;
	outline-width: 1px;
}

.ui.page.modals.dimmer.transition.visible.active {
	display: flex !important;
}

.step.workView {
	border-right: 1px solid rgba(252, 252, 252, 0.5) !important;
}

.step.workView::after {
	background-color: #2285d1 !important;
	border-color: rgba(251, 251, 251, 0.5) !important;
}

.link.step.workView:hover,
.link.step:active {
	color: rgba(255, 255, 255, 0.8) !important;
}

.link.step.workView.active {
	background-color: #1e91e9 !important;
}

.link.step.workView.toBeCompleted {
	background-color: #a5a5a5 !important;
}

.link.step.workView.toBeCompleted::after {
	background-color: #a5a5a5 !important;
}

.link.step.workView.active::after {
	background-color: #1e91e9 !important;
}

.step.workView.active .title,
.step.workView.active .currentIcon {
	color: #ffffff !important;
}

.link.step.workView {
	cursor: default !important;
}

.workView.gridColumn {
	height: 84vh;
	overflow-y: auto;
	overflow-x: hidden;
}

/* Tasks list CSS */
.toTop {
	position: fixed;
	bottom: 1.5vh;
	right: 5vh;
	z-index: 999;
}

.hideToTop {
	display: none !important;
}

.showToTop {
	display: block !important;
}

.cursorPointer {
	cursor: pointer;
}

.inputLabelHeader {
	background-color: 'transparent';
	font-size: '1rem';
	color: 'rgba(0,0,0,.8)';
	padding-left: '0';
}

.dropdownTaskBoxDiv {
	padding-bottom: 2em;
}

.dropdownFloat {
	float: left !important;
}

/* Sidebar Styling */
.omni-sidebar {
	margin-top: 4.5rem !important;
	z-index: 99 !important;
	color: black !important;
}

.omni-sidebar-menu-item {
	padding: 11px 13px 0 13px !important;
}

.omni-sidebar-item {
	height: 30px !important;
	padding: 6px 16px !important;
	margin: 10px 0 !important;
	color: black !important;
}

.omni-sidebar-item-small {
	height: 30px !important;
	padding: 6px 16px !important;
	margin: 10px 0 !important;
	color: #989898 !important;
	font-weight: 200 !important;
}

.omni-sidebar-item:hover,
.omni-sidebar-item-small:hover {
	background-color: #f2f2f2 !important;
	color: #0f4b90 !important;
}

.omni-sidebar-item:active,
.omni-sidebar-item-small:active {
	background-color: #f2f2f2 !important;
	border-color: #0f4b90 !important;
	color: #0f4b90 !important;
}

/* Login Page Styling */
.omni-login-page {
	height: 100vh;
	background-color: gray;
	background-image: url('./img/background.jpeg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow-y: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
}

.omni-login-page-form {
	width: 30%;
	padding: 160px 0 0 0;
}

.omni-login-page-logo {
	max-width: 400px;
	max-height: 400px;
	padding: 90px 50px 0 0;
}

/* Landing Page */
.omni-landing-page-container {
	height: 100%;
	width: 100%;
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.omni-landing-page-item-logo {
	text-align: right;
	margin: 75px 0;
	padding: 75px 200px;
	width: 100%;
}

.omni-landing-page-item-quote {
	align-self: center;
	padding: 0 200px;
	width: 100%;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.daysToCellRenderer {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	text-align: center;
	width: 100%;
}

.ui.feed>.event>.content .extra.text{
	white-space: pre-wrap;
	font-size: 0.9rem;
}

@media only screen and (min-width: 768px) {
	.ui.search.dropdown.createModalDropdown .menu {
		max-height: 6.68571429rem !important;
	}
}
@media only screen and (min-width: 992px) {
	.ui.search.dropdown.createModalDropdown .menu {
		max-height: 8.02857143rem !important;
	}
}
@media only screen and (min-width: 1920px) {
	.ui.search.dropdown.createModalDropdown .menu {
		max-height: 10.37142857rem !important;
	}
}

/* added for loaders not showing up in work creation modal */
/* https://github.com/Semantic-Org/Semantic-UI-React/issues/3133 */
.ui.dimmer .ui.workaround.loader:before {
	border-color: rgba(0,0,0,.1);
  }
.ui.dimmer .ui.workaround.loader:after {
border-color: #767676 transparent transparent;
}
